<template>
  <validation-observer ref="observer" rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @close="onClose, $emit('close')"
      submit="Submit"
      :loading="isLoading"
      button-class="bg-dynamicBackBtn text-white"
      @submit="handleSubmit(onSubmit)"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <BackButton @onClick="onClose(), $emit('close')" variant="secondary" />
          <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            Discretionary Adjustment
          </span>
        </div>
      </template>
      <template>
        <div class="border-b border-dashed border-romanSilver mb-5">
          <div class="flex justify-between items-start w-full gap-2">
            <div class="w-4/12 h-60 rounded-md text-blueCrayola font-normal">
                <img class="h-56 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
                <div v-else class="h-56 border rounded-md flex justify-center items-center">
                <span style="font-size:60px">
                  {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
                </span>
                </div>
              </div>
            <div class="w-8/12 flex flex-col justify-start items-start gap-2">
              <h4 class="font-bold text-lg leading-5 text-darkPurple">
                {{ employeeInfo.fname }} {{ employeeInfo.lname }}
              </h4>
              <div class="flex flex-col justify-start items-center w-full gap-2">
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Function:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.function }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Designation:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.designation }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Job Level:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.level">
                      {{ employeeInfo.level.name }}
                    </span>
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Office Location:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.location }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Manager:
                  </p>
                  <template v-if="(employeeInfo.lineManager)">
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.lineManager.fname }} {{ employeeInfo.lineManager.lname }}
                    </p>
                  </template>
                  <p v-else>-</p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Length of Service:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.yearsOfService">
                      {{ employeeInfo.yearsOfService }}
                    </span>
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Last Performance Score:
                  </p>
                  <template v-if="employeeInfo.performanceScore">
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.performanceScore }}
                      <span class="text-red-400" v-if="employeeInfo.performanceScore <= 2">(Poor)</span>
                      <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 2 && employeeInfo.performanceScore < 4)">
                        (Average)
                      </span>
                      <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 4)">(Excellent)</span>
                    </p>
                  </template>
                  <p v-else>-</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="checkAdjustment">
        <div class="w-full px-10 py-2 flex self-center mb-2 rounded-md bg-yellow-100 border-carrotOrange border">
          <Icon icon-name="icon-info" size="xs" class="text-carrotOrange" />
          <span class="text-carrotOrange w-full rounded-md text-center">
            Adjustment is outside employee salary range
          </span>
        </div>
      </template>
      <template>
        <div class="flex justify-between items-center">
          <p class="font-normal text-sm leading-5 text-jet w-1/5">
            Adjust by
          </p>
          <div class="flex justify-start items-center gap-2 w-4/5">
            <Select
              placeholder="Select"
              :rules="['required']"
              class="w-2/5 text-base"
              v-model="adjustBy"
              :options="[
                { name: '(₦) Amount', id: 'amount'},
                { name: '(%) Increase', id: 'percentage_increase'},
                { name: '(%) Decrease', id: 'percentage_decrease'},
              ]"
            />
            <TextInput
              min="0"
              type="number"
              placeholder="Input"
              class="w-3/5"
              v-model.number="payload.adjustBy"
              :rules="['required']"
              @input="payload.adjustBy = Math.abs(payload.adjustBy)"
            />
          </div>
        </div>
      </template>
      <template>
        <div class="flex flex-col justify-start items-start gap-5 my-5">
          <div class="flex justify-between items-center w-full">
            <p class="font-semibold text-sm leading-5 text-romanSilver">
              Salary Range:
            </p>
            <p class="font-semibold text-sm leading-5 text-jet">
              <span v-if="employeeInfo.paygrade">
                {{ convertToCurrency(employeeInfo.paygrade.rangeMinimum) }}
                -
                {{ convertToCurrency(employeeInfo.paygrade.rangeMaximum) }}
              </span>
              <span v-else> - </span>
            </p>
          </div>
          <div class="flex justify-between items-center w-full">
            <p class="font-semibold text-sm leading-5 text-romanSilver">
              Current Annual Gross
            </p>
            <p class="font-semibold text-sm leading-5 text-jet">
              <span v-if="employeeInfo.employee">
                {{ convertToCurrency(employeeInfo.employee.grossPay) }}
              </span>
              <span v-else> - </span>
            </p>
          </div>
          <div class="flex justify-between items-center w-full">
            <p class="font-semibold text-sm leading-5 text-romanSilver">
              Adjusted Annual Gross:
            </p>
            <p class="font-semibold text-sm leading-5 text-jet">
              <span v-if="employeeInfo.employee">
                {{ computedAnnualGross(employeeInfo.employee.grossPay) }}
              </span>
            </p>
          </div>
        </div>
      </template>
      <div class="flex flex-col justify-start items-start gap-5">
        <template>
          <div class="w-8/12">
            <span class="font-semibold text-base leading-5 text-darkPurple">
              Effective
            </span>
            <RadioButton
              v-model="isEffectiveDate"
              space-between="mr-2 ml-2 text-jet text-base font-normal leading-5"
              :options="[
                { name: 'Immediately', value: 'immediately' },
                { name: 'Set Date', value: 'set_date'},
              ]"
            />
          </div>
          <div v-if="isEffectiveDate === 'set_date'" class="w-full">
            <div class="w-full flex flex-col justify-start gap-2">
              <p class="text-jet text-sm mr-5">Start Date</p>
              <validation-provider class="w-full" name="Effective date" :rules="{required: true}" v-slot="{ errors }">
                <div :class="errors[0] ? 'border border-desire rounded-md' : 'date-flex'">
                  <datepicker
                    placeholder="--Start Date--"
                    input-class="date-input"
                    style="width:100%;"
                    :rules="['required']"
                    v-model="payload.effectiveDate"
                  />
                </div>
                <small class="text-desire">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </template>
        <template>
          <div class="">
            <span class="font-semibold text-base leading-5 text-darkPurple">
              Select who to notify
            </span>
            <RadioButton
              space-between="mr-2 ml-2 text-jet text-base font-normal leading-5"
              v-model="payload.whoToNotify"
              :options="[
                { name: 'Employee', value: 'employee' },
                { name: 'Employee’s Manager', value: 'employer'},
              ]"
            />
          </div>
        </template>
        <validation-provider class="w-full" name="Justification" :rules="{required: true}" v-slot="{ errors }">
          <div class="flex flex-col justify-start items-start w-full gap-1">
            <span class="font-normal text-sm text-darkPurple">Justification</span>
            <div class="w-full flex flex-col justify-start gap-2">
              <Textarea
                class="w-full"
                label="Justification"
                placeholder="Text Area"
                :height="144" :maxlength="3"
                v-model="payload.justification"
              />
              <div class="w-full flex flex-row justify-between text-sm">
                <p v-if="payload.justification.length > 300" class="text-flame w-full whitespace-nowrap">
                  * Justification should not exceed max count of 300
                </p>
                <p class="font-normal text-xs leading-5 text-jet flex justify-end w-full">
                  {{ payload.justification.length }}/300
                </p>
              </div>
            </div>
          </div>
          <span class="text-flame">{{ errors[0] }}</span>
        </validation-provider>
        <template>
          <div class="flex flex-col justify-start items-start w-full gap-2 -mt-2">
              <span class="text-base font-semibold leading-5 text-darkPurple">
                Attach Letter
              </span>
              <div class="w-full flex items-center justify-center border border-dashed p-3 border-romanSilver rounded-md">
              <div @click="isOpenAttachment = !isOpenAttachment"
                class="bg-ghostWhite p-5 rounded-md shadow cursor-pointer">
                <Icon @click="isOpenAttachment = !isOpenAttachment" icon-name="icon-plus-grey" size="s" />
              </div>
            </div>
          </div>
        </template>
        <template>
        <div class="hover:bg-ghostWhite rounded-md cursor-pointer">
          <div class="flex justify-center items-start gap-2 px-3 py-2">
            <Icon icon-name="icon-plus" size="xs" class="text-flame" />
            <p class="font-semibold text-base leading-5 text-flame">Create a Template</p>
          </div>
        </div>
      </template>
      <template v-if="isOpenAttachment">
        <div class="overlayer flex flex-wrap justify-center items-center">
          <Card class="bg-white relative p-4 flex flex-col justify-end items-end gap-2">
            <div @click="isOpenAttachment = !isOpenAttachment">
              <Icon
                icon-name="close"
                size="xs"
                class="text-darkPurple cursor-pointer"
              />
            </div>
            <file-upload />
          </Card>
        </div>
      </template>
      </div>
      <Modal v-if="isAdjustmentWarning">
        <Card class=" p-2 px-3 flex flex-col gap-3 max-w-md">
          <template>
            <div class="border-b border-romanSilver">
              <p class="text-jet font-bold text-base">Action Required!</p>
            </div>
            <div class="flex flex-col justify-start gap-2">
              <div class="relative mb-5">
                <Alert
                  message="Adjustment is outside employee salary range"
                  variant="primary"
                  :time="2"
                  class="alert-style"
                />
                <div class="flex flex-col justify-start items-start gap-1">
                  <span class="font-semibold text-sm text-jet">Salary Range:
                     <p class="font-semibold text-sm leading-5 text-jet">
                      <span v-if="employeeInfo.paygrade">
                        {{ convertToCurrency(employeeInfo.paygrade.rangeMinimum) }}
                          -
                        {{ convertToCurrency(employeeInfo.paygrade.rangeMaximum) }}
                      </span>
                      <span v-else> - </span>
                    </p>
                  </span>
                  <span class="font-semibold text-sm text-jet">Current Annual Gross:
                    {{ convertToCurrency(employeeInfo.employee.grossPay) }}
                  </span>
                  <span class="font-semibold text-sm text-jet">Adjusted Annual Gross:
                    {{ computedAnnualGross(employeeInfo.employee.grossPay) }}
                  </span>
                </div>
              </div>
              <p>Do you wish to continue?</p>
              <div class="flex justify-end items-center gap-2">
                <Button
                  class="button-class button-class-secondary btn-border"
                  label="Cancel"
                  @onClick="isAdjustmentWarning = false"
                />
                <Button
                  class="button-class button-class-pimary btn-bg"
                  label="Continue"
                  @onClick="onSubmitDiscretionarydjustment"
                />
              </div>
            </div>
          </template>
        </Card>
      </Modal>
    </RightSideBar>
  </validation-observer>
</template>

<script>
  import * as _ from 'lodash';
  import { format } from "date-fns";
  import Datepicker from "vuejs-datepicker";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import Alert from "@scelloo/cloudenly-ui/src/components/alert";
  import FileUpload from '@scelloo/cloudenly-ui/src/components/file-upload';
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import Button from "@scelloo/cloudenly-ui/src/components/button";
  import TextInput from "@scelloo/cloudenly-ui/src/components/text";
  import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
  import Select from "@scelloo/cloudenly-ui/src/components/select";
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import RadioButton from "@scelloo/cloudenly-ui/src/components/radio";
  import RightSideBar from "@/components/RightSideBar";
  import Icon from "@/components/Icon";
  import Modal from "@/components/Modal";

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      Datepicker,
      FileUpload,
      BackButton,
      RadioButton,
      TextInput,
      Textarea,
      Select,
      Card,
      Alert,
      Button,
      Modal,
      RightSideBar,
      Icon
    },
    props: {
      reviewType: {
        type: String,
        default: ''
      }
    },
    data(){
      return {
        reviewId: '',
        adjustBy: '',
        isOpen: false,
        isLoading: true,
        isOpenAttachment: false,
        isAdjustmentWarning: false,
        employeeInfo: {},
        revisionParameter: '',
        adjustedAnnualGross: '',
        isEffectiveDate: 'immediately',
        payload: {
          adjustBy: '',
          whoToNotify: 'employee',
          justification: '',
          effectiveDate: new Date(),
        }
      }
    },
    methods: {
      toggle({ userId, paygradeId, reviewId }){
        this.reviewId = reviewId;
        this.getEmployeeCompensationSummary({ userId, paygradeId })
        this.isOpen = true
      },
      onClose(){
        this.isOpen = false
        this.isLoading = false
        _.assign(this.$data.payload, this.$options.data().payload)
      },
      getEmployeeCompensationSummary(payload){
        this.isLoading = true
        this.$_getEmployeeCompensationSummary(payload).then(({ data }) => {
          this.employeeInfo = {
            ...data.employee,
            ...data.employee.paygrade,
            ...data.employee.userDetails,
          }
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isLoading = false
        })
      },
      computedAnnualGross(value){
        /* eslint-disable no-param-reassign */
        if(_.isEqual(this.adjustBy, 'amount')){
          value += this.payload.adjustBy
        } if(_.isEqual(this.adjustBy, 'percentage_increase')){
          value = Math.fround(((Math.abs(this.payload.adjustBy) / 100) * value) + value)
        } if(_.isEqual(this.adjustBy, 'percentage_decrease')){
          value = Math.fround(value - ((Math.abs(this.payload.adjustBy) / 100) * value))
        }
        this.adjustedAnnualGross = value
        return this.convertToCurrency(value)
      },
      onSubmit(){
        const adjustedGross = this.adjustedAnnualGross
        const {rangeMinimum, rangeMaximum} = this.employeeInfo.paygrade
        this.$refs.observer.validate().then((isValidated) => {
          if(isValidated){
            if(_.inRange(adjustedGross, rangeMinimum, rangeMaximum)){
              this.onSubmitDiscretionarydjustment()
            } else this.isAdjustmentWarning = true
          }
        })
      },
      onSubmitDiscretionarydjustment(){
        this.isAdjustmentWarning = false
        this.isLoading = true

        const payload = {
          ...this.payload,
          orgId: this.$orgId,
          reviewId: this.reviewId,
          reviewType: this.reviewType,
          reviseFactor: this.adjustBy,
          reviseBy: this.$AuthUser.id,
          userId: this.employeeInfo.userId,
          effectiveDate: format(this.payload.effectiveDate, "yyyy-MM-dd")
        }

        this.$_postDescretionaryAdjustment(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.$emit('success', data.success)
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      }
    },
    computed: {
      checkAdjustment(){
        if(this.payload.adjustBy){
          const {rangeMinimum, rangeMaximum} = this.employeeInfo.paygrade
          return !_.inRange(this.adjustedAnnualGross, rangeMinimum, rangeMaximum)
        }
        return false
      },
    },
  }
</script>

<style scoped>
  .rightSidebar{
    width: 540px !important;
  }
  .button_class {
    padding: 10px 40px !important;
    font-weight: 600;
    display: flex;
    justify-self: end;
  }
  .button-class-pimary {
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
  }
  .button-class-secondary {
    color: #321c3b !important;
    background: white !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
  }
  .alert-style {
    position: relative;
    right: 0; top: -5px;
    box-shadow: none;
    background: #FFFFFF;
    color: #E99323;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid rgb(254 202 202);
  }

  .btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>

